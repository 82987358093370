.top-border {
    height: 100%;
    background-image: url(./images/header-bg-jaali.png), linear-gradient(to bottom, #871719 0%,#6c0d16  83%);
    background-repeat: repeat;
}

.info {
    background-image: url(./images/footer-bg-jaali.png), linear-gradient(to bottom, #871719 0%,#6c0d16  83%);
    background-repeat: repeat;
    padding: 4.8em 0 0;
}

.bottom-border {
    background: url(./images/footer-design-pattern.png) repeat-x;
    height: 43px;
    margin: 2em 0 0;
}

.copy {
    color: #ffffff;
    padding: 0;
    margin-bottom: 0px;
    text-align: center;
}

.mb_YTPBar .mb_YTPUrl.ytpicon {
    display: none;
}

.ant-page-header-footer {
    margin-top: 0px;
}

.mb_YTPBar span.mb_YTPTime {
    display: none;
}

.mb_YTPBar span.mb_OnlyYT {
    left: 176px;
    right: auto;
}

.mb_YTPBar {
    opacity:1 !important;
}

.mb_YTPBar .buttonBar {
    background-color: #000 !important;
    height: 30px;
}

.mb_YTPBar .mb_YTPProgress {
    display: none !important;
}

.ytp-pause-overlay {
    display: none !important;
}
